import { FuturesTradeModeEnum } from '@/constants/future/common'
import { EntrustTypeEnum, OrderTabTypeEnum } from '@/constants/order'

/** 获取现货订单页面路径 */
export function getSpotOrderPagePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/spot/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}

/** 获取合约订单页面路径 */
export function getFutureOrderPagePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/future/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}
/** 获取带单订单界面路径 */
export function getOrderInterfacePath(tab: OrderTabTypeEnum, entrustType?: EntrustTypeEnum) {
  return `/orders/takerOrder/${tab}${entrustType ? `?type=${entrustType}` : ''}`
}

// 获取下单页合约订单页面路径
export function getFutureModeOrderPagePath(
  tab: OrderTabTypeEnum,
  entrustType?: EntrustTypeEnum,
  tradeMode?: FuturesTradeModeEnum
) {
  if (tradeMode === FuturesTradeModeEnum.copyTrading) {
    return getOrderInterfacePath(tab, entrustType)
  }
  return getFutureOrderPagePath(tab, entrustType)
}
